import { useContext } from "react";
import * as EnvConstants from "../Constants/EnvConstants";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { v1 } from 'uuid';

export const UUID = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const queryString = window.location.href.split("?")[1];
  const urlParams = new URLSearchParams(queryString);
  const checkUuid = urlParams.get('uuid');

  const getUUID = (split_name, type) => {
    let uuids = "";
    if (type == 'check') {
      const local_storage_uuid = localStorage.getItem('uuid');
      let local_storage_split_name = localStorage.getItem('split_name');
      if (checkUuid) {
        uuids = checkUuid;
      } else if (local_storage_uuid !== null) {
        if (local_storage_split_name != split_name) {
          uuids = "";
        } else {
          uuids = local_storage_uuid;
        }
      } else {
        uuids = "";
      }
    } else {
      uuids = "";
    }
    if (uuids == "") {
      uuids = EnvConstants.AppAdtopiaUPID + '-' + v1();
      localStorage.clear();      
    }
    return uuids;
  }
  const fetchUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData.length != 0) {
      var uuid = visitorData.uuid;
    }
    else {
      const query_uuid = urlParams.get("uuid");
      const local_storage_uuid = localStorage.getItem('uuid');
      if (query_uuid === undefined || query_uuid === null || query_uuid == '') {
        var uuid = local_storage_uuid;
      } else {
        var uuid = query_uuid;
      }
    }
    return uuid;
  }
  return { getUUID, fetchUuid }
}